<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",

  data() {
    return {
      navigation: [
        { link: "HomeView", name: "Главная" },
        { link: "AboutView", name: "О компании" },
        { link: "ServicesView", name: "Услуги" },
        { link: "NewsView", name: "Новости" },
        { link: "RatingView", name: "Рейтинг букмекеров" },
        { link: "LeonView", name: "БК LEON" },
        { link: "ContactsView", name: "Контакты" },
      ],

      window_width: 0,
      show_mobile_menu: false,
    };
  },

  watch: {
    window_width(newValue) {
      if (newValue > 1023) {
        this.show_mobile_menu = false;
      }
    },

    $route() {
      this.show_mobile_menu = false;
    },
  },

  methods: {
    setWindowWidth() {
      this.window_width = window.innerWidth;
    },
  },

  mounted() {
    window.addEventListener("resize", this.setWindowWidth);
    this.setWindowWidth();
  },
});
</script>

<template>
  <header class="header">
    <div class="container">
      <router-link :to="{ name: 'HomeView' }">
        <img src="./assets/logotype.svg" alt="logotype" />
      </router-link>

      <nav v-if="window_width > 1023">
        <router-link
          :to="{ name: item.link }"
          v-for="item in navigation"
          :key="item.link"
        >
          {{ item.name }}
        </router-link>
      </nav>

      <nav v-if="show_mobile_menu && window_width < 1024">
        <router-link
          :to="{ name: item.link }"
          v-for="item in navigation"
          :key="item.link"
        >
          {{ item.name }}
        </router-link>
      </nav>

      <button @click="show_mobile_menu = !show_mobile_menu">
        <img src="./assets/Icon/button.svg" alt="" v-if="!show_mobile_menu" />
        <img src="./assets/Icon/button_close.svg" alt="" v-else />
      </button>
    </div>
  </header>

  <main><router-view /></main>

  <footer class="footer">
    <div class="container">
      <div class="footer__info">
        <img src="./assets/logotype_white.svg" alt="logotype" />

        <div class="footer__company">
          <section>
            <h3>Юридический адрес:</h3>
            <p>
              195248, г. Санкт-Петербург, ш. Революции, д. 41/39, лит. А, пом.
              16-H, оф. 6
            </p>
          </section>

          <section>
            <h3>Электронная почта:</h3>

            <a href="mailto:hello@sportad.ru">hello@sportad.ru</a>
          </section>

          <p>Материалы сайта предназначены для лиц старше 18 лет (18+)</p>

          <span>© 2023 Sportad </span>

          <span>
            Все права защищены. При использовании материалов сайта гиперссылка
            на sportad.ru обязательна.
          </span>
        </div>
      </div>

      <div class="map">
        <iframe
          src="https://yandex.ru/map-widget/v1/?ll=30.441465%2C59.959104&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NzQzNDUzNBJR0KDQvtGB0YHQuNGPLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsywg0YjQvtGB0YHQtSDQoNC10LLQvtC70Y7RhtC40LgsIDQxLzM5IgoNqITzQRW31m9C&tab=services&z=16.26"
          width="560"
          height="400"
          frameborder="1"
          allowfullscreen="true"
          style="position: relative"
        />
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.header {
  padding: ($base-space * 6) 0;
  background: $color-white;
  box-shadow: 0px 1px 5px rgba(99, 99, 99, 0.1);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 28px;

      @media (max-width: 1023px) {
        position: fixed;
        background-color: $color-white;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: $base-space * 6;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(100% - 77px);

        @media (max-width: 1280px) {
          padding: 32px;
        }

        @media (max-width: 767px) {
          padding: 16px;
        }
      }

      > a {
        @include body-medium();
        color: $color-black;

        &.router-link-active {
          color: $color-blue;
        }
      }
    }

    > button {
      display: none;

      @media (max-width: 1023px) {
        display: block;
      }
    }
  }
}

.footer {
  padding: ($base-space * 13) 0;
  background-color: $color-black;
  margin: ($base-space * 30) 0 0;

  @media (max-width: 1023px) {
    padding: ($base-space * 9) 0;
    margin: ($base-space * 15) 0 0;
  }

  > .container {
    display: grid;
    grid-template-columns: 480px 1fr;
    gap: 160px;

    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      gap: 36px;
    }
  }

  &__company {
    margin: ($base-space * 13) 0 0;
    max-width: 480px;

    @media (max-width: 1023px) {
      margin: ($base-space * 9) 0 0;
    }

    > section {
      &:not(:first-of-type) {
        margin: ($base-space * 6) 0 0;
      }

      > h3 {
        @include h-5();
        color: $color-white;
      }

      > p,
      a {
        margin: ($base-space * 3) 0 0;
        display: block;
        @include body-2();
        color: $color-light-gray;
      }
    }

    > p {
      margin: ($base-space * 13) 0 0;
      @include body-regular();
      color: $color-white;

      @media (max-width: 1023px) {
        margin: ($base-space * 9) 0 0;
      }
    }

    > span {
      display: block;
      color: $color-light-gray;
      @include body-regular();

      &:first-of-type {
        margin: ($base-space * 13) 0 0;

        @media (max-width: 1023px) {
          margin: ($base-space * 9) 0 0;
        }
      }

      &:last-of-type {
        margin: ($base-space * 2) 0 0;
      }
    }
  }
}

main {
  padding: 77px 0 0;
}

.map {
  > iframe {
    width: 100%;
    border: none;

    @media (max-width: 1023px) {
      height: 420px;
    }
  }
}
</style>
