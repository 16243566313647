import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: () =>
      import(/* webpackChunkName: "HomeView" */ "../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "AboutView",
    component: () =>
      import(/* webpackChunkName: "AboutView" */ "../views/AboutView.vue"),
  },
  {
    path: "/services",
    name: "ServicesView",
    component: () =>
      import(
        /* webpackChunkName: "ServicesView" */ "../views/ServicesView.vue"
      ),
  },
  {
    path: "/news",
    name: "NewsView",
    component: () =>
      import(/* webpackChunkName: "NewsView" */ "../views/NewsView.vue"),
  },
  {
    path: "/rating",
    name: "RatingView",
    component: () =>
      import(/* webpackChunkName: "RatingView" */ "../views/RatingView.vue"),
  },
  {
    path: "/leon",
    name: "LeonView",
    component: () =>
      import(/* webpackChunkName: "RatingView" */ "../views/LeonView.vue"),
  },
  {
    path: "/contacts",
    name: "ContactsView",
    component: () =>
      import(
        /* webpackChunkName: "ContactsView" */ "../views/ContactsView.vue"
      ),
  },
];

const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
